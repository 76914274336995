.root {
  display: flex;
}

.form-control {
  min-width: 150px;
  background-color: white;
}

.input {
  padding-left: 10px;
  color: #fff !important;

  &::before {
    border-bottom: 1px solid rgb(255, 255, 255) !important;
  }

  svg {
    color: #fff !important;
  }
}
